<template>
	<div id="contribute">
		<!-- 投稿专区 -->
		<webSearth @searthChange="searthChangeFun" />
		<div class="part1" v-if="hidden">
			<div class="tit">
				<p>项目概况</p>
				<b>Project Overview</b>
			</div>

			<div class="cont">
				<div class="item">
					<span>{{ProjectData.doctorNum}}</span>
					<p>注册人数</p>
					<b>participants</b>
				</div>
				<div class="item">
					<span>{{ProjectData.documentNum}}</span>
					<p>投稿数量</p>
					<b>contribute</b>
				</div>
				<div class="item">
					<span>{{ProjectData.excellentNum}}</span>
					<p>入围作品</p>
					<b>Excellent articles</b>
				</div>
			</div>
		</div>
		<div class="part2" v-if="hidden">
			<!-- <div class="tit">
				<p>优秀作品</p>
				<b>works of excellence</b>
			</div> -->

			<div class="cont">
				<el-tabs v-model="active" @tab-click="handleClick">
					<el-tab-pane label="入围作品" name="1"></el-tab-pane>
					<el-tab-pane label="精彩佳作" name="2"></el-tab-pane>
				</el-tabs>

				<el-table :data="tableData" style="width: 100%" @row-click="pageToDetails" :fit="true">
					<el-table-column>
						<template slot-scope="scope">
							<el-col :span="3">
								编号：{{scope.row.id}}
								<el-divider direction="vertical"></el-divider>
							</el-col>
							<el-col :span="17">
								{{scope.row.documentName}}
							</el-col>
							<el-col :span="4">
								<el-divider direction="vertical"></el-divider>
								作者：{{scope.row.uploader}}
							</el-col>
						</template>
					</el-table-column>
				</el-table>

				<!-- <div class="vedio" v-if="active == 'VEDIO'">
					<div class="vedioItem" v-for="item in tableData" :key="item.id" @click="pageToDetails(item)">
						<div class="img">
							<el-image :src="item.imagePath" fit="contain"></el-image>
							<img class="play" :src="require('@/assets/imgs/play.png')" alt="">
						</div>
						<p>{{item.documentName}}</p>
					</div>
				</div> -->

				<el-pagination background layout="prev, pager, next" :total="total"
					@current-change="handleCurrentChange" :current-page.sync="pageNum" :page-size="pageSize"
					:hide-on-single-page="true"></el-pagination>
			</div>
		</div>
		<div class="part2" v-if="!hidden">
			<!-- <div class="tit">
				<p>入围作品</p>
				<b>works of excellence</b>
			</div> -->

			<div class="cont">
				<!-- <el-tabs v-model="active2" @tab-click="handleClick2">
					<el-tab-pane label="文章" name="TEXT"></el-tab-pane>
					<el-tab-pane label="视频" name="VEDIO"></el-tab-pane>
				</el-tabs> -->

				<el-table :data="tableData2" style="width: 100%" @row-click="pageToDetails">
					<el-table-column>
						<template slot-scope="scope">
							<el-col :span="3">
								编号：{{scope.row.id}}
								<el-divider direction="vertical"></el-divider>
							</el-col>
							<el-col :span="17">
								{{scope.row.documentName}}
							</el-col>
							<el-col :span="4">
								<el-divider direction="vertical"></el-divider>
								作者：{{scope.row.uploader}}
							</el-col>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" :total="total2"
					@current-change="handleCurrentChange2" :current-page.sync="pageNum2" :page-size="pageSize"
					:hide-on-single-page="true"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getProject, // 首页项目介绍
		excellentDocument, // 优秀稿件
		documentList // 入围作品
	} from '@/request/api.js';
	import webSearth from '@/components/webSearth'
	export default {
		name: 'contribute',
		components: {
			webSearth
		},
		data() {
			return {
				searth: '',
				active: '1',
				total: 0,
				pageSize: 12,
				pageNum: 1,
				tableData: [],
				total2: 0,
				pageNum2: 1,
				tableData2: [],
				hidden: true,
				ProjectData: ''
			}
		},
		created() {
			this.getQueryFun();
			this.getProjectFun();
			this.getexcellentDocument();
			this.getdocumentList()
		},
		mounted() {},
		watch: {
			"$route": {
				handler(val) {
					this.getQueryFun(val);
				},
				deep: true
			}
		},
		methods: {
			// 搜索
			searthChangeFun(val) {
				this.hidden = false;
				this.pageNum2 = 1;
				this.getdocumentList(val.content)
			},

			// 获取入围作品列表
			getdocumentList(val) {
				let data = {
					size: this.pageSize,
					current: this.pageNum2
				}
				data.documentName = val
				documentList(data).then(res => {
					// console.log(res)
					if (res.code == 200) {
						this.total2 = res.data.total;
						this.tableData2 = res.data.records;
					}
				})
			},

			// 切换筛选类型
			handleClick(tab, event) {
				this.getexcellentDocument();
			},
			handleClick2(tab, event) {
				this.getdocumentList()
			},
			// 获取优秀稿件列表
			getexcellentDocument() {
				let data = {
					current: this.pageNum,
					size: this.pageSize,
					documentType: this.active
				}

				excellentDocument(data).then(res => {
					if (res.code == 200) {
						this.total = res.data.total;
						this.tableData = res.data.records;
					}
				})
			},

			// 首页项目介绍
			getProjectFun() {
				getProject().then(res => {
					if (res.code == 200) {
						let data = res.data;
						this.ProjectData = data;
					}
				})
			},

			getQueryFun(val) {
				if (this.$route.query.type == 'searth') {
					this.hidden = false;
				} else {
					this.hidden = true;
				}
			},

			handleCurrentChange(val) {
				this.pageNum = val;
				this.getexcellentDocument();
			},
			handleCurrentChange2(val) {
				this.pageNum2 = val;
				this.getdocumentList()
			},

			// 跳转到详情页面
			pageToDetails(row) {
				window.open(`${row.documentPath}?key=${row.userId}&type=article`)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#contribute {
		display: flex;
		flex-direction: column;

		.part1,
		.part2 {
			padding: 100px 0 0;

			.tit {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 60px;

				p {
					font-size: 36px;
					text-align: center;
					color: rgba(31, 32, 34, 1);
				}

				b {
					font-size: 18px;
					text-align: center;
					color: rgba(203, 50, 44, 1);
				}
			}

			.cont {
				width: 1200px;
				margin: auto;

				::v-deep .el-tabs {
					.el-tabs__nav {
						display: block;
						width: 100%;
						display: flex;
						flex-direction: row;

						.el-tabs__item {
							flex: 1;
							text-align: center;
							transition: all .3s;
							z-index: 10;
						}

						.el-tabs__item.is-active {
							// background-color: #cb322c;
							color: #F5F8FF;
						}

						.el-tabs__active-bar {
							height: 100%;
						}
					}
				}

				::v-deep .el-table {
					.el-table__header {
						height: 0;

						.el-table__cell {
							padding: 0;
							height: 0;
						}


					}

					.el-table__row {
						.cell {
							font-size: 15px;
							padding: 5px 10px;
							display: flex;
							align-items: center;
							cursor: pointer;

							.el-col {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}

							.el-divider {
								margin: 0 10px;
								background-color: #666;

							}
						}
					}
				}


			}
		}

		.part1 {
			.cont {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.item {
					display: flex;
					flex-direction: column;

					span {
						width: 270px;
						height: 300px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #F5F8FF;
						border-radius: 4px;
						font-size: 70px;
						font-weight: bold;
						color: rgba(203, 50, 44, 1);
						margin-bottom: 30px;
					}

					p {
						font-size: 30px;
						color: rgba(31, 32, 34, 1);
						text-align: center;
					}

					b {
						text-align: center;
						font-size: 22px;
						color: rgba(134, 150, 160, 1);
					}
				}
			}
		}

		.part2 {
			.cont {
				padding: 40px 35px;
				box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

				::v-deep .el-tabs {

					.el-tabs__item {
						font-size: 20px;

						&:hover {
							color: rgba(203, 50, 44, .8);
						}
					}

					.el-tabs__item.is-active {
						color: rgba(203, 50, 44, 1);
					}

					.el-tabs__active-bar {
						background-color: rgba(203, 50, 44, 1);
					}
				}

				.el-pagination {
					text-align: center;
					margin-top: 20px;
				}

				.document,
				.vedio {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: flex-start;

					.vedioItem {
						width: 23%;
						margin-bottom: 30px;
						margin-right: 30px;
						display: flex;
						flex-direction: column;

						&:nth-child(4n) {
							margin-right: 0;
						}

						.img {
							position: relative;

							.el-image {
								width: 100%;
								border-radius: 4px;
							}

							.play {
								position: absolute;
								left: 50%;
								top: 50%;
								width: 100px;
								height: 100px;
								transform: translate(-50%, -50%);
								opacity: .8;
							}
						}

						p {
							font-size: 16px;
							margin-top: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
</style>