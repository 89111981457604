<template>
	<div id="webSearth">
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>查询优秀科普文章</p>
					<b>Search for excellent popular science articles</b>
					<el-input placeholder="请输入文章标题/作者..." v-model="searth" @change="searthChange" @clear="searthChange"
						clearable>
						<i slot="suffix" class="el-input__icon el-icon-search" @click="searthChange"
							style="cursor: pointer;"></i>
					</el-input>

				</div>
				<div class="cont2">
					<b>项目周期：2023年3月-2023年12月</b>
					<b>主办单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" style="height: 36px;" alt=""></b>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getConfig, // 首页获取logo banner 流程图  
	} from '@/request/api.js';
	export default {
		name: 'webSearth',
		components: {},
		data() {
			return {
				banner: '',
				searth: '',
			}
		},
		created() {
			this.getConfigFun();
		},
		mounted() {},
		methods: {
			searthChange() {
				let path = this.$route.path;
				if (path != '/contribute') {
					this.$router.push({
						path: '/contribute',
						query: {
							type: 'searth'
						}
					})
				}
				this.$emit('searthChange', {
					content: this.searth
				})
			},

			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#webSearth {
		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				width: 1200px;
				height: 60vh;
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: auto;
				background-color: #ccc;

				.cont2 {
					display: flex;
					flex-direction: column;
					margin-top: 20px;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}

				.cont {
					z-index: 10;
					width: 660px;
					display: flex;
					flex-direction: column;

					p {
						text-align: center;
						font-size: 36px;
						color: rgba(115, 115, 115, 1);
					}

					b {
						text-align: center;
						font-size: 14px;
						color: rgba(203, 50, 44, 1);
						margin-bottom: 40px;
					}

					::v-deep .el-input {
						height: 60px;
						display: flex;
						flex-direction: row;
						align-items: center;
						box-shadow: 10px 10px 50px 0px rgba(103, 114, 148, 0.3);
						border: none;
						padding: 0 90px 0 50px;
						background-color: #fff;
						border-radius: 4px;
						position: relative;


						.el-input__inner {
							height: auto;
							font-size: 24px;
							border: none;
							flex: 1;
							padding: 0;
							display: flex;

						}

						.el-input__suffix {
							position: inherit;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 40px;

							.el-input__suffix-inner {
								font-size: 40px;
								height: 40px;
								width: 40px;
								line-height: 1;
								display: flex;
								align-items: center;
								justify-content: center;
								color: rgba(203, 50, 44, 1);
							}
						}

						.el-icon-search {
							display: block;
							width: 40px;
							height: 40px;
							position: absolute;
							right: -100%;
							top: 50%;
							transform: translateY(-50%);
						}

						.el-icon__clear {
							display: block;
						}
					}
				}
			}
		}
	}
</style>